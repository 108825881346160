import * as Sentry from '@sentry/svelte';
import { get } from 'svelte/store';
import { client } from '../store';
/**
 * Log to the console
 */
class Logger {
    constructor() {
        this.logPrefix = '[THE-WINDOW] ';
    }
    init() {
        if (!process.env.LOGGING_ENABLED) {
            return;
        }
        Sentry.init({
            dsn: process.env.LOGGING_API_KEY,
            integrations: [new Sentry.BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }
    /**
     * Log whatever you want on the info level.
     */
    info(message, ...optionalParams) {
        console.info(`${this.logPrefix}${message}`, ...optionalParams);
    }
    /**
     * Log a warning.
     */
    warn(message, error) {
        console.warn(`${this.logPrefix}${message}`, error || '');
    }
    /**
     * Log an error.
     */
    error(message, error) {
        const customData = {
            message,
        };
        const clientState = get(client);
        if (clientState) {
            const { id, status, roomId, name } = get(client);
            customData.clientName = name;
            customData.clientId = id;
            customData.clientStatus = status;
            if (roomId) {
                customData.clientRoomId = roomId;
            }
        }
        Sentry.captureException(error, { tags: customData });
        console.error(`${this.logPrefix}${message}`, error);
    }
}
export default new Logger();
