import mitt from 'mitt';
import logger from '../utils/logger';
export var PhoneMessageType;
(function (PhoneMessageType) {
    PhoneMessageType["PhoneOnHook"] = "phone_on_hook";
    PhoneMessageType["PhoneOffHook"] = "phone_off_hook";
})(PhoneMessageType || (PhoneMessageType = {}));
const RECONNECT_TIME = 10;
class PhoneService {
    constructor() {
        this.wsUrl = 'ws://localhost:4242/v1/socket';
        this.ringInterval = 0;
        this.ringing = false;
        this.reconnectTimeout = 0;
        this.events = mitt();
    }
    connect() {
        this.socket = new WebSocket(this.wsUrl);
        this.socket.addEventListener('open', this.onSocketOpen.bind(this));
        this.socket.addEventListener('close', this.onSocketClose.bind(this));
        this.socket.addEventListener('message', this.onSocketMessage.bind(this));
        this.socket.addEventListener('error', this.onSocketError.bind(this));
    }
    startRing() {
        if (this.ringing)
            return;
        this.ringing = true;
        this.ringInterval = window.setInterval(() => {
            this.socket.send('ring_start');
        }, 1000);
    }
    stopRing() {
        if (!this.ringing)
            return;
        clearInterval(this.ringInterval);
        setTimeout(() => this.socket.send('ring_stop'), 100);
        this.ringing = false;
    }
    onSocketOpen() {
        this.events.emit('open');
    }
    onSocketClose() {
        this.events.emit('close');
        this.reconnect();
    }
    onSocketMessage(e) {
        switch (e.data) {
            case PhoneMessageType.PhoneOnHook:
                this.onPhoneOnHookMessage();
                break;
            case PhoneMessageType.PhoneOffHook:
                this.onPhoneOffHookMessage();
                break;
        }
    }
    onPhoneOnHookMessage() {
        this.events.emit('on_hook');
    }
    onPhoneOffHookMessage() {
        this.events.emit('off_hook');
    }
    onSocketError() {
        logger.warn(`Failed to open phone service websocket connection, retrying after ${RECONNECT_TIME} seconds...`);
        this.reconnect();
    }
    reconnect() {
        if (this.reconnectTimeout)
            window.clearTimeout(this.reconnectTimeout);
        this.reconnectTimeout = window.setTimeout(this.connect.bind(this), RECONNECT_TIME * 1000);
    }
}
export default new PhoneService();
