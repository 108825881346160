<script lang="ts">
  import Spinner from '../App/Spinner.svelte';
</script>

<Spinner />
<h1>Waiting for the other window...</h1>
<p>
  It appears you are not connected to another window right now. As soon as the other window is up
  and running, their video will show up here.
</p>
<p><strong>Don’t worry, on this side everything looks peachy.</strong></p>
