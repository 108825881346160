const stack = process.env.JITSI_STACK;
const domain = `${stack}.onavstack.net`;
export const config = {
    hosts: {
        domain,
        muc: `conference.${domain}`,
        focus: `focus.${domain}`,
    },
    hiddenDomain: `hidden-services.${domain}`,
    websocket: `wss://${domain}/xmpp/xmpp-websocket`,
    serviceUrl: `wss://${domain}/xmpp/xmpp-websocket`,
    bosh: `https://${domain}/xmpp/http-bind`,
    websocketKeepAlive: -1,
    openBridgeChannel: 'websocket',
    resolution: 720,
    constraints: {
        video: {
            frameRate: 30,
            height: { ideal: 720, max: 720, min: 180 },
            width: { ideal: 1280, max: 1280, min: 320 },
        },
    },
    disableSimulcast: false,
    enableRemb: true,
    enableTcc: true,
    disableRtx: false,
    useStunTurn: true,
    p2p: {
        enabled: true,
        useStunTurn: true,
        enableUnifiedOnChrome: true,
        stunServers: [{ urls: 'stun:turn.avstack.net:443' }],
        preferredCodec: 'VP8',
    },
    e2eping: {
        enabled: false,
        pingInterval: -1,
        numRequests: 5,
        maxConferenceSize: 200,
        maxMessagesPerSecond: 250,
    },
    deploymentInfo: { userRegion: 'fra', environment: 'avstack', releaseNumber: '8319' },
    testing: {
        enableThumbnailReordering: true,
        mobileXmppWsThreshold: 100,
        setScreenSharingResolutionConstraints: true,
    },
    flags: {
        sourceNameSignaling: false,
        sendMultipleVideoStreams: false,
        receiveMultipleVideoStreams: true,
    },
    disableModeratorIndicator: false,
    disableSelfView: false,
    disableSelfViewSettings: false,
    channelLastN: -1,
    lastNLimits: {},
    startAudioMuted: null,
    startWithAudioMuted: false,
    startSilent: false,
    startVideoMuted: null,
    maxFullResolutionParticipants: 2,
    enableLayerSuspension: true,
    enableForcedReload: true,
    enableEncodedTransformSupport: false,
    enableLipSync: false,
    disablePolls: false,
    disableReactions: false,
    useNewBandwidthAllocationStrategy: true,
    enableUnifiedOnChrome: true,
    videoQuality: {
        preferredCodec: 'VP8',
        enforcePreferredCodec: false,
        maxBitratesVideo: {
            low: 200000,
            standard: 500000,
            high: 1500000,
            H264: { low: 200000, standard: 500000, high: 1500000 },
            VP8: { low: 200000, standard: 500000, high: 1500000 },
            VP9: { low: 200000, standard: 500000, high: 1500000 },
        },
        minHeightForQualityLvl: { '240': 'standard', '540': 'high' },
        resizeDesktopForPresenter: false,
    },
    startBitrate: '800',
    enableOpusRed: false,
    audioQuality: { stereo: false, opusMaxAverageBitrate: null, enableOpusDtx: false },
    disableAudioLevels: true,
    audioLevelsInterval: 200,
    stereo: false,
    disableAEC: false,
    disableAGC: false,
    disableAP: false,
    disableHPF: false,
    disableNS: false,
    desktopSharingFrameRate: { min: 5, max: 5 },
    forceJVB121Ratio: -1,
    disableFilmstripAutohiding: false,
    requireDisplayName: false,
    hideDisplayName: false,
    disableJoinLeaveSounds: false,
    enableWelcomePage: false,
    enableClosePage: false,
    defaultLanguage: 'en',
    noticeMessage: null,
    prejoinPageEnabled: false,
    prejoinConfig: { enabled: false, hideDisplayName: false },
    readOnlyName: false,
    enableInsecureRoomNameWarning: true,
    enableNoisyMicDetection: false,
    enableNoAudioDetection: false,
    enableTalkWhileMuted: false,
    disableDeepLinking: true,
    transcribingEnabled: false,
    transcribeWithAppLanguage: true,
    preferredTranscriptionLanguage: null,
    autoCaptionOnRecord: false,
    transcription: {
        enabled: false,
        useAppLanguage: true,
        preferredLanguage: null,
        disableStartForAll: false,
        autoCaptionOnRecord: false,
    },
    liveStreamingEnabled: false,
    liveStreaming: { enabled: false, termsLink: null, dataPrivacyLink: null },
    fileRecordingsEnabled: false,
    recordingService: { enabled: false, sharingEnabled: false, hideStorageWarning: true },
    fileRecordingsServiceEnabled: false,
    fileRecordingsServiceSharingEnabled: false,
    localRecording: { disable: true, notifyAllParticipants: true, disableSelfRecording: true },
    faceLandmarks: {
        enableFaceCentering: false,
        enableFaceExpressionsDetection: false,
        enableDisplayFaceExpressions: false,
        enableRTCStats: false,
        faceCenteringThreshold: 10,
        captureInterval: 1000,
    },
    toolbarButtons: [
        'camera',
        'chat',
        'closedcaptions',
        'desktop',
        'download',
        'embedmeeting',
        'etherpad',
        'feedback',
        'filmstrip',
        'fullscreen',
        'hangup',
        'help',
        'highlight',
        'invite',
        'linktosalesforce',
        'livestreaming',
        'microphone',
        'noisesuppression',
        'participants-pane',
        'profile',
        'raisehand',
        'recording',
        'security',
        'select-background',
        'settings',
        'shareaudio',
        'sharedvideo',
        'shortcuts',
        'stats',
        'tileview',
        'toggle-camera',
        'videoquality',
        'whiteboard',
        '__end',
    ],
    toolbarConfig: { initialTimeout: 20000, timeout: 4000, alwaysVisible: false },
    notifications: null,
    disabledNotifications: [],
    disableShortcuts: false,
    enableCalendarIntegration: false,
    googleApiApplicationClientID: '',
    dialInConfCodeUrl: null,
    dialInNumbersUrl: null,
    dialOutAuthUrl: null,
    dialOutCodesUrl: null,
    brandingDataUrl: null,
    disableThirdPartyRequests: false,
    analytics: {
        disabled: true,
        amplitudeAPPKey: null,
        googleAnalyticsTrackingId: null,
        matomoEndpoint: null,
        matomoSiteID: null,
        rtcstatsEnabled: false,
        rtcstatsEndpoint: `wss://${domain}/rtcstats`,
        rtcstatsPollInterval: 30000,
        rtcstatsSendSdp: false,
    },
    callStatsID: null,
    callStatsSecret: null,
    feedbackPercentage: null,
    gravatar: { disabled: false, baseUrl: 'https://www.gravatar.com/avatar/' },
    logging: {
        defaultLogLevel: 'trace',
        disableLogCollector: true,
        loggers: {
            'modules/RTC/TraceablePeerConnection.js': 'info',
            'modules/statistics/CallStats.js': 'info',
            'modules/xmpp/strophe.util.js': 'log',
        },
    },
    whiteboard: { enabled: false, collabServerBaseUrl: null },
};
