<script lang="ts">
  import { client, clientId, remoteTracks } from '../../store';
  import { isDevelopment } from '../../utils/env';
  import { onDestroy } from 'svelte';

  $: showDebugBar = isDevelopment() || $client?.status !== 'in-conversation';

  if (isDevelopment()) {
    document.body.classList.add('debug');
  }

  onDestroy(() => {
    document.body.classList.remove('debug');
  });
</script>

{#if showDebugBar}
  <div class="debug">
    <div class="debug-title">
      The Window
      {#if isDevelopment()} - DEV{/if}
    </div>
    <div class="debug-stats">
      <strong>Status:</strong>
      <span>{$client?.status}</span>
      <strong>clientId:</strong>
      <span>{$clientId}</span>
      <strong>RoomId:</strong>
      <span>{$client?.roomId}</span>
      {#if $client?.location}
        <strong>Location:</strong>
        <span>{$client?.location}</span>
      {/if}
      {#if isDevelopment()}
        <strong>Tracks:</strong>
        <span>{Object.keys($remoteTracks).length}</span>
      {/if}
    </div>
  </div>
{/if}

<style>
  .debug {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #4b9cbc;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 3;
  }

  .debug-title {
    font-weight: 700;
  }

  .debug-stats strong {
    margin-left: 10px;
  }
</style>
