<script lang="ts">
  import type { Track } from '../../services/jitsi-service';
  import { onDestroy, afterUpdate } from 'svelte';
  import logger from '../../utils/logger';

  export let track: Track;

  const CHECK_TIMEOUT_TIME = 30;

  let videoElement: HTMLVideoElement;
  let checkTimeout: number;

  afterUpdate(() => {
    track.track.detach(videoElement);
    track.track.attach(videoElement);
    logger.info('Attach video track');

    if (checkTimeout) {
      clearTimeout(checkTimeout);
    }

    checkTimeout = window.setTimeout(checkStream, CHECK_TIMEOUT_TIME * 1000);
  });

  onDestroy(() => {
    track.track.detach(videoElement);
    logger.info('Detach video track');

    if (checkTimeout) {
      clearTimeout(checkTimeout);
    }
  });

  function checkStream() {
    if (videoElement?.paused) {
      try {
        throw new Error('Video element stream unexpectedly paused and could not be played.');
      } catch (e) {
        logger.error('Video stream unexpectedly paused.', e);
      }
    }
  }
</script>

<!-- svelte-ignore a11y-media-has-caption -->
<video bind:this={videoElement} autoplay class="video-container" id="video-{track.participantId}" />

<style>
  .video-container {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 2;
    position: absolute;
  }
</style>
