<script lang="ts">
  import { onMount } from 'svelte';

  import jitsiService from '../../services/jitsi-service';
  import phoneService from '../../services/phone-service';
  import { microphoneEnabled, otherMicrophoneEnabled } from '../../store';
  import logger from '../../utils/logger';

  let audioElement: HTMLAudioElement;

  onMount(() => {
    jitsiService.events.on('ring_start', onRingStart);
    jitsiService.events.on('ring_stop', onRingStop);

    phoneService.events.on('on_hook', onHook);
    phoneService.events.on('off_hook', offHook);
  });

  $: enableAudio = $microphoneEnabled && !$otherMicrophoneEnabled;

  $: enableAudio ? audioElement?.play() : audioElement?.pause();

  function onHook() {
    $microphoneEnabled = false;
    jitsiService.sendRingStopCommand();
  }

  function offHook() {
    $microphoneEnabled = true;
    jitsiService.sendRingStartCommand();
    phoneService.stopRing();
  }

  function onRingStart() {
    if ($microphoneEnabled) {
      return;
    }

    logger.info('Phone ring start');
    phoneService.startRing();
  }

  function onRingStop() {
    logger.info('Phone ring stop');
    phoneService.stopRing();
  }

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'e') {
      jitsiService.sendRingStartCommand();
    }

    if (e.key === 'q') {
      jitsiService.sendRingStopCommand();
    }
  }
</script>

<svelte:window on:keydown={onKeyDown} />
<audio bind:this={audioElement} autoplay loop preload="auto">
  <source src="/audio/phone-dial.mp3" type="audio/mp3" />
</audio>
