<script lang="ts">
  import type { Track } from '../../services/jitsi-service';
  import { onDestroy, afterUpdate } from 'svelte';
  import logger from '../../utils/logger';

  export let track: Track;

  let audioElement: HTMLAudioElement;

  afterUpdate(() => {
    track.track.detach(audioElement);
    track.track.attach(audioElement);
    logger.info('Attach audio track');
  });

  onDestroy(() => {
    track.track.detach(audioElement);
    logger.info('Detach audio track');
  });
</script>

<audio bind:this={audioElement} autoplay id="audio-{track.participantId}" />
