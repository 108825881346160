import { reload, RELOAD_TIMEOUT } from './browser';
import logger from './logger';
let cachedBundle;
export function startClientUpdatePoller() {
    setTimeout(pollClientUpdates, RELOAD_TIMEOUT * 1000);
}
async function pollClientUpdates() {
    try {
        const response = await fetch(window.location.origin + '/main.js?' + Date.now());
        const bundle = await response.text();
        if (!cachedBundle) {
            cachedBundle = bundle;
        }
        if (cachedBundle !== bundle) {
            logger.info('Bundle has changed! Reloading...');
            reload();
        }
        else {
            setTimeout(pollClientUpdates, RELOAD_TIMEOUT * 1000);
        }
    }
    catch (e) {
        logger.error('Failed to fetch new bundle from poll.', e);
    }
}
