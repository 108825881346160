import { doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { get } from 'svelte/store';
import { firestore } from '../firebase/firebase';
import { client } from '../store';
import logger from '../utils/logger';
export function clientRef(id) {
    return doc(firestore, `clients/${id}`);
}
export async function setClientOnline(id) {
    const ref = clientRef(id);
    await setDoc(ref, { lastSeen: serverTimestamp(), status: 'online' }, { merge: true });
}
export async function getClientById(id) {
    return await getDoc(clientRef(id));
}
export async function resetClientReload(id) {
    await updateDoc(clientRef(id), { reload: false });
}
export async function setClientStatus(id, status) {
    try {
        await updateDoc(clientRef(id), { status });
    }
    catch (e) {
        logger.error(`Failed to set client status from ${get(client).status} to ${status}`, e);
    }
}
