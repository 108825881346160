<script lang="ts">
  export let message: string;
</script>

<div class="error-message">
  {message}
</div>

<style>
  .error-message {
    position: absolute;
    z-index: 4;
    background-color: #e74c3c;
    color: white;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    max-width: 90vw;
    padding: 10px 20px;
    border-radius: 4px;
  }
</style>
