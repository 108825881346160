import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { auth } from './firebase';
import logger from '../utils/logger';
export function getUserId() {
    return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userId = user.uid.toLowerCase();
                logger.info('Signed in user', userId);
                return resolve(userId);
            }
            await signIn();
        });
    });
}
export async function signIn() {
    try {
        await signInAnonymously(auth);
    }
    catch (e) {
        logger.error('Failed to anonymously sign in user', e);
    }
}
