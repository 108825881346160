import { onSnapshot, } from 'firebase/firestore';
export function mapValues(snap) {
    const values = [];
    if (snap.empty) {
        return values;
    }
    snap.forEach((doc) => {
        values.push(Object.assign(Object.assign({}, doc.data()), { id: doc.id }));
    });
    return values;
}
export function subscribe(ref, cb) {
    return onSnapshot(ref, (snap) => {
        cb(snap.exists() ? Object.assign(Object.assign({}, snap.data()), { id: snap.id }) : undefined);
    });
}
export function subscribeAll(ref, cb) {
    return onSnapshot(ref, (snap) => {
        cb(mapValues(snap));
    });
}
