import mitt from 'mitt';
import { remoteTracks, remoteLocation, clientId, client, errorMessage, microphoneEnabled, otherMicrophoneEnabled, } from '../store';
import logger from '../utils/logger';
import { reload, RELOAD_TIMEOUT } from '../utils/browser';
import { setClientStatus } from './client-service';
import { config } from './config';
class JitsiService {
    constructor() {
        this.stack = process.env.JITSI_STACK;
        this.domain = `${this.stack}.onavstack.net`;
        this.connection = null;
        this.room = null;
        this.client = null;
        this.clientId = null;
        this.localTracks = [];
        this.remoteTracks = {};
        this.events = mitt();
        this.subscribeState();
    }
    connect() {
        const credentials = {
            id: `${this.clientId}@${this.domain}`,
            password: this.client.roomId,
        };
        logger.info('Connecting with credentials', credentials);
        this.connection.connect(credentials);
    }
    disconnect() {
        logger.info('Disconnected from room', this.room);
        this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess.bind(this));
        this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed.bind(this));
        this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect.bind(this));
    }
    sendRingStartCommand() {
        var _a;
        (_a = this.room) === null || _a === void 0 ? void 0 : _a.sendCommandOnce('ring_start', { attributes: { clientId: this.clientId } });
    }
    sendRingStopCommand() {
        var _a;
        (_a = this.room) === null || _a === void 0 ? void 0 : _a.sendCommandOnce('ring_stop', { attributes: { clientId: this.clientId } });
    }
    subscribeState() {
        client.subscribe((c) => (this.client = c));
        clientId.subscribe((id) => (this.clientId = id));
        remoteTracks.subscribe((tracks) => (this.remoteTracks = tracks));
        microphoneEnabled.subscribe((enabled) => this.toggleLocalAudio(!enabled));
    }
    async initConnection() {
        try {
            const configResponse = await fetch(`https://${this.domain}/config.json`);
            this.connectionOptions = await configResponse.json();
        }
        catch (e) {
            logger.error('Failed to fetch remote config, using fallback instead.', e);
            this.connectionOptions = config;
        }
        logger.info('Initialising Jitsi connection');
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.WARN);
        JitsiMeetJS.init({
            disableAP: false,
            disableAEC: false,
            disableNS: false,
            disableAGC: false,
            disableHPF: false,
            disableResponsiveTiles: true,
            disableAudioLevels: false,
            maxFullResolutionParticipants: 0,
        });
        this.connection = new JitsiMeetJS.JitsiConnection(null, null, this.connectionOptions);
        this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess.bind(this));
        this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed.bind(this));
        this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect.bind(this));
    }
    async onConnectionSuccess() {
        this.room = this.connection.initJitsiConference(this.client.roomId, this.connectionOptions);
        this.room.setDisplayName(this.client.location);
        this.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined.bind(this));
        this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrackAdded.bind(this));
        this.room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onRemoteTrackRemoved.bind(this));
        this.room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, this.onTrackMuteChanged.bind(this));
        this.room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined.bind(this));
        this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft.bind(this));
        this.room.join();
        setTimeout(() => {
            this.room.addCommandListener('ring_start', this.onRingStart.bind(this));
            this.room.addCommandListener('ring_stop', this.onRingStop.bind(this));
        }, 5000);
        await setClientStatus(this.clientId, 'in-room');
    }
    onConnectionFailed(error) {
        logger.error('Connection failed.', error);
        logger.info(`Reloading page in ${RELOAD_TIMEOUT}s...`);
        setTimeout(() => {
            logger.info(`Reloading after ${RELOAD_TIMEOUT}s`);
            reload();
        }, RELOAD_TIMEOUT * 1000);
    }
    async onConferenceJoined() {
        logger.info('Conference joined, creating local tracks');
        try {
            const tracks = await JitsiMeetJS.createLocalTracks(Object.assign(Object.assign({}, this.connectionOptions), { devices: ['video', 'audio'] }));
            this.onLocalTracks(tracks);
        }
        catch (error) {
            logger.error('Failed to create local tracks for audio and/or video. Retrying with video only...', error);
            try {
                const tracks = await JitsiMeetJS.createLocalTracks(Object.assign(Object.assign({}, this.connectionOptions), { devices: ['video'] }));
                this.onLocalTracks(tracks);
            }
            catch (error) {
                logger.error('Failed to create local tracks for video.', error);
                errorMessage.set('Er is iets misgegaan met het opvragen van je camera en/of microfoon. Controleer of deze goed zijn ingesteld en aangesloten.');
            }
        }
    }
    onLocalTracks(tracks) {
        this.localTracks = tracks.map((track) => ({ track, participantId: 'local' }));
        for (let i = 0; i < this.localTracks.length; i++) {
            const track = this.localTracks[i];
            logger.info('Adding local track', track);
            this.room
                .addTrack(track.track)
                .then(() => logger.info('Local tracks added to room'))
                .catch((error) => logger.error('Failed to add local track to room', error));
        }
        this.toggleLocalAudio(true);
    }
    toggleLocalAudio(mute) {
        this.localTracks.forEach((local) => {
            if (local.track.type === 'audio') {
                mute ? local.track.mute() : local.track.unmute();
            }
        });
    }
    onRemoteTrackAdded(track) {
        if (track.isLocal()) {
            return;
        }
        const type = track.getType();
        const participantId = track.getParticipantId();
        logger.info(`Remote track added of type ${type} from participant ${participantId}`);
        const newRemoteTracks = Object.assign({}, this.remoteTracks);
        if (!newRemoteTracks[participantId]) {
            newRemoteTracks[participantId] = {};
        }
        newRemoteTracks[participantId][type] = { track, participantId };
        remoteTracks.set(newRemoteTracks);
        otherMicrophoneEnabled.set(!track.muted);
    }
    onRemoteTrackRemoved(track) {
        logger.info(`Remote track removed of type ${track.getType()} from participant ${track.getParticipantId()}`);
    }
    onTrackMuteChanged(track) {
        if (track.isLocal()) {
            return;
        }
        otherMicrophoneEnabled.set(!track.muted);
    }
    async onUserJoined(id, user) {
        logger.info('User joined room', id);
        remoteLocation.set(user.getDisplayName());
        await setClientStatus(this.clientId, 'in-conversation');
    }
    async onUserLeft(id) {
        logger.info('User left room', id);
        const newRemoteTracks = Object.assign({}, this.remoteTracks);
        delete newRemoteTracks[id];
        remoteTracks.set(newRemoteTracks);
        remoteLocation.set(null);
        await setClientStatus(this.clientId, 'in-room');
    }
    onRingStart(e) {
        var _a;
        if (((_a = e.attributes) === null || _a === void 0 ? void 0 : _a.clientId) === this.clientId)
            return;
        this.events.emit('ring_start');
    }
    onRingStop(e) {
        var _a;
        if (((_a = e.attributes) === null || _a === void 0 ? void 0 : _a.clientId) === this.clientId)
            return;
        this.events.emit('ring_stop');
    }
}
export default new JitsiService();
