<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import { get } from 'svelte/store';
  import type { Unsubscribe } from '@firebase/util';
  import type { DocumentReference } from '@firebase/firestore';
  import type { Client } from 'shared/db-types';

  import Debug from './components/App/Debug.svelte';
  import Phone from './components/App/Phone.svelte';
  import ErrorMessage from './components/App/ErrorMessage.svelte';
  import Spinner from './components/App/Spinner.svelte';
  import Waiting from './components/States/Waiting.svelte';
  import NotPaired from './components/States/NotPaired.svelte';
  import VideoTrack from './components/Tracks/VideoTrack.svelte';
  import AudioTrack from './components/Tracks/AudioTrack.svelte';

  import { setClientOnline, clientRef, resetClientReload } from './services/client-service';
  import {
    remoteTracks,
    remoteLocation,
    clientId,
    client,
    errorMessage,
    microphoneEnabled,
  } from './store';
  import jitsiService from './services/jitsi-service';
  import phoneService from './services/phone-service';
  import { getUserId } from './firebase/auth-helpers';
  import { startClientUpdatePoller } from './utils/poll';
  import logger from './utils/logger';
  import { reload } from './utils/browser';
  import { subscribe } from './firebase/firestore-helpers';

  startClientUpdatePoller();

  let clientUnsubscribe: Unsubscribe | undefined;
  let initialLoading = true;
  let initialised = false;

  onMount(async () => {
    await login();
    await register();

    clientUnsubscribe = subscribe<Client>(
      clientRef($clientId) as DocumentReference<Client>,
      onClientUpdate
    );
  });

  onDestroy(clientUnsubscribe);

  async function login() {
    try {
      $clientId = await getUserId();
    } catch (e) {
      logger.error('Failed to login client', e);
    }
  }

  async function register() {
    try {
      await setClientOnline($clientId);
    } catch (e) {
      logger.error('Failed to set/update client online status/lastSeen', e);
    }
  }

  async function onClientUpdate(newClient: Client | undefined) {
    const oldClient = get(client);
    $client = newClient;

    if ($client?.reload) {
      await handleClientReload();
      return;
    }

    if (!initialised && $client?.roomId) {
      await jitsiService.initConnection();
      logger.info('Room id assigned to client');
      jitsiService.connect();
      phoneService.connect();
    } else if (initialised && $client?.roomId !== oldClient?.roomId) {
      reload();
    }

    initialised = true;
    initialLoading = false;
  }

  async function handleClientReload() {
    logger.info('Reloading client as requested by admin');

    try {
      await resetClientReload($clientId);
      reload();
    } catch (e) {
      logger.error('Failed to reload client as requested by admin', e);
    }
  }

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === ' ' && !$microphoneEnabled) {
      $microphoneEnabled = true;
    }
  }

  function onKeyUp(e: KeyboardEvent) {
    if (e.key === ' ') {
      $microphoneEnabled = false;
    }
  }
</script>

<svelte:window on:keydown={onKeyDown} on:keyup={onKeyUp} />

{#if $errorMessage}
  <ErrorMessage message={$errorMessage} />
{/if}

<Debug />
<Phone />

{#if Object.keys($remoteTracks).length === 0}
  <div class="loader-container">
    <img src="img/thewindow-logo.svg" class="logo" alt="The Window logo" />
    <div class="loader-content">
      {#if initialLoading}
        <Spinner />
      {:else if $client?.roomId}
        <Waiting />
      {:else}
        <NotPaired />
      {/if}
    </div>
  </div>
{:else}
  {#each Object.entries($remoteTracks) as [_, tracks]}
    {#if tracks}
      <div class="video-container">
        {#if tracks.video}
          <VideoTrack track={tracks.video} />
        {/if}
        {#if tracks.audio}
          <AudioTrack track={tracks.audio} />
        {/if}
        {#if $microphoneEnabled}
          <div class="microphone-state" />
        {/if}
        {#if !!$client?.showLocation && !!$remoteLocation}
          <div class="location-container">
            <div class="location-subtitle">Say hello to</div>
            <div class="location-title">{$remoteLocation}</div>
          </div>
        {/if}
      </div>
    {/if}
  {/each}
{/if}

<style>
  .logo {
    position: absolute;
    top: 40px;
    width: 150px;
  }

  .loader-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    background-image: url('/img/wallpaper.png');
    background-position: bottom;
    background-size: cover;
  }

  .loader-content {
    max-width: 500px;
    text-align: center;
  }

  .video-container {
    position: relative;
  }

  .microphone-state {
    position: absolute;
    left: 16px;
    top: 16px;
    border-radius: 100%;
    background: #e74c3c;
    opacity: 0.7;
    width: 20px;
    height: 20px;
    z-index: 4;
  }

  .location-container {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .location-subtitle {
    color: white;
    font-size: 24px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    margin-bottom: -12px;
  }
  .location-title {
    color: white;
    font-size: 42px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    font-weight: 900;
  }
</style>
